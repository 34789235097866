export const Footer = (params) => {
  return <div className="Footer">
    <a href="/dn/privacy.pdf">Polityka prywatności</a>
    <a href="/dn/procedura.pdf">Procedura zgłaszania</a>
    <a href="/dn/procedura-przyjmowania-zgloszen.pdf">Procedura przyjmowania zgłoszeń</a>
    <a href="/dn/report.pdf">Instrukcja zgłaszania</a>
    {params.admin ? <>
      <a href="/">Użytkownik</a>
      {/* <a href="/">Utwórz profil firmy</a> */}
      <a className="mobile" href="/api/user/logout">
        Wyloguj
      </a>
    </> : <>
      <a href="/admin" className="mobile">
        Administrator
      </a>
      <div className="Button">
        <div></div>
        <button onClick={() => document.location = "/"}>Utwórz zgłoszenie</button>
      </div>
    </>}
  </div>;
}
